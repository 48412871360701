import { persistCombineReducers } from 'redux-persist';
// Use sessionStorage instead of localStorage
import storageSession from 'redux-persist/lib/storage/session'; // sessionStorage
// reducers
import user from './modules/user';
import loader from './modules/loader';
import search from './modules/search';

const userPersistConfig = {
    key: 'admin-app',
    storage: storageSession,  // Use sessionStorage
    blacklist: ['loader'],    // Optionally blacklist parts of the state to avoid persisting them
};

export default persistCombineReducers(userPersistConfig, {
    loader,
    user,
    search
});
