import { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import "./scss/main.scss";
import configureStoreProd from "./config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy } from "react";

const { persistor, store } = configureStoreProd();

function App() {

  const routes = [
    { url: "/", element: <Navigate to="/login" /> },
    { url: "*", path: "NotFoundPage" }, // Not Found Page
    { url: "/login", path: "Login" }, // Auth Page Routes
    { url: "/forgotpassword", path: "Forgotpassword" },
    { url: "/resetpassword", path: "Resetpassword" },
    { url: "/dashboard", path: "Dashboard" }, // Dashboard Route
    { url: "/profile", path: "Profile" }, // Profile Page Routes
    { url: "/profile/:tab", path: "Settings" },
    { url: "/contactus", path: "ContactUs" },
    { url: "/contactus/detail/:id", path: "ContactUs/View" },
    { url: "/user", path: "Users" }, // Users Module Routes
    { url: "/user/edit/:id", path: "Users/AddEdit" },
    { url: "/user/add", path: "Users/AddEdit" },
    { url: "/user/detail/:id", path: "Users/View" },
    { url: "/category", path: "Category" }, // Category Module Routes
    { url: "/category/edit/:id", path: "Category/AddEdit" },
    { url: "/category/add", path: "Category/AddEdit" },
    { url: "/category/detail/:id", path: "Category/View" },
    { url: "/subcategory", path: "SubCategory" }, // Sub-Category Module Routes
    { url: "/subcategory/edit/:id", path: "SubCategory/AddEdit" },
    { url: "/subcategory/add", path: "SubCategory/AddEdit" },
    { url: "/subcategory/detail/:id", path: "SubCategory/View" },
    { url: "/amenities", path: "Amenities" }, // Amenities Module Routes
    { url: "/amenities/edit/:id", path: "Amenities/AddEdit" },
    { url: "/amenities/add", path: "Amenities/AddEdit" },
    { url: "/amenities/detail/:id", path: "Amenities/View" },
    { url: "/venues", path: "Venues" }, // Venues Module Routes
    { url: "/venues/edit/:id", path: "Venues/AddEdit" },
    { url: "/venues/add", path: "Venues/AddEdit" },
    { url: "/venues/detail/:id", path: "Venues/View" },
    { url: "/host", path: "Host" }, // Host Module Routes
    { url: "/host/edit/:id", path: "Host/AddEdit" },
    { url: "/host/add", path: "Host/AddEdit" },
    { url: "/host/detail/:id", path: "Host/View" },
    { url: "/post", path: "Posts" }, // Posts Module Routes
    { url: "/post/edit/:id", path: "Posts/AddEdit" },
    { url: "/post/add", path: "Posts/AddEdit" },
    { url: "/post/detail/:id", path: "Posts/View" },
    { url: "/event", path: "Events" }, // Events Module Routes
    { url: "/event/edit/:id", path: "Events/AddEdit" },
    { url: "/event/add", path: "Events/AddEdit" },
    { url: "/event/detail/:id", path: "Events/View" },
    { url: "/feature", path: "Features" }, // Features Module Routes
    { url: "/feature/detail/:id", path: "Features/View" },
    { url: "/plan", path: "Plans" }, // Plan Module Routes
    { url: "/plan/edit/:id", path: "Plans/AddEdit" },
    { url: "/plan/add", path: "Plans/AddEdit" },
    { url: "/plan/detail/:id", path: "Plans/View" },
    { url: "/event-organizer", path: "EventOrganizer" }, // Event Organizer Module Routes
    { url: "/event-organizer/edit/:id", path: "EventOrganizer/AddEdit" },
    { url: "/event-organizer/add", path: "EventOrganizer/AddEdit" },
    { url: "/event-organizer/detail/:id", path: "EventOrganizer/View" },
    { url: "/claim/venue", path: "ClaimVenues" }, // Claim Venue Module Routes
    { url: "/claim/venue/detail/:id", path: "ClaimVenues/View" },
    { url: "/explorer", path: "Explorer" }, // Explorer Module Routes
    { url: "/explorer/detail/:id", path: "Explorer/View" },
    { url: "/member", path: "Members" }, // Members Module Routes
    { url: "/member/detail/:id", path: "Members/View" },
    { url: "/transactions/:tab?", path: "Transactions" },
    { url: "/ticket/transaction/:id", path: "TicketsTransactions/View" },
    { url: "/plan/transaction/detail/:id", path: "PlanTransactions/View" },
    { url: "/jobpost/transaction/:id", path: "JobPostTransactions/View" },
    { url: "/platform/fee/transaction/:id", path: "PlatformFeeTransactions/View" },
    { url: "/commission/transaction/:id", path: "CommissionTransactions/View" },
    { url: "/influencer", path: "Influencer" }, // Influencer Module Routes
    { url: "/influencer/detail/:id", path: "Influencer/View" },
    { url: "/entertainment/crew", path: "EntertainmentCrew" }, // Entertainment Crew Module Routes
    { url: "/entertainment/crew/detail/:id", path: "EntertainmentCrew/View" },
    { url: "/crew/member", path: "CrewMember" }, // Crew Member Module Routes
    { url: "/crew/member/detail/:id", path: "CrewMember/View" },
    { url: "/vendor", path: "Vendor" }, // Vendor Module Routes
    { url: "/vendor/detail/:id", path: "Vendor/View" },
    { url: "/foodTruckVendor", path: "FoodTruckVendor" }, // Food Truck Vendor Module Routes
    { url: "/foodTruckVendor/detail/:id", path: "FoodTruckVendor/View" },
    { url: "/balance", path: "Balance" }, // Balance Module Routes
  ];

  // sessionStorage.clear();

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={"loading ..."} persistor={persistor}>
          <Suspense
            fallback={
              <div id="loader" className="loaderDiv">
                <div>
                  <img
                    src="/assets/img/loader.gif"
                    alt="logo"
                    className="loaderlogo"
                  />
                </div>
              </div>
            }
          >
            <Router>
              <Routes>
                {routes.map((itm, index) => {
                  const Element = lazy(() => import(`./Pages/${itm.path}`));
                  return (
                    <Route
                      path={itm.url}
                      key={index}
                      element={itm.path ? <Element /> : itm.element}
                    />
                  );
                })}
              </Routes>
            </Router>
          </Suspense>
        </PersistGate>
      </Provider>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" className="toasterDiv" />
    </>
  );
}

export default App;
